<template>
  <div>
    <h2 class="hidden">Discover history, one day at a time</h2>
    <div class="mt-8 flex flex-col md:flex-row gap-4">
      <a href="https://testflight.apple.com/join/w5fTlNIy">
        <img src="~/assets/App-Store.png" alt="App Store" class="h-12" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.almanacc.app">
        <img src="~/assets/Google-Play.png" alt="Google Play" class="h-12" />
      </a>
    </div>
    <button
      class="group absolute bottom-12 left-6 pr-6 bg-black/30 backdrop-blur-sm rounded-full cursor-pointer transition-all duration-300 ease-in border-2"
      :class="[!!imageCount ? 'border-primary-500' : 'border-orange-500']"
      @click="isExpanded = !isExpanded"
    >
      <div class="flex items-center p-2">
        <NumberFlow :value="imageCount" class="text-white" />
        <div
          class="absolute right-0 mr-2 rounded-full h-4 w-4 animate-pulse"
          :class="[!!imageCount ? 'bg-primary-500' : 'bg-orange-500']"
        ></div>
        <span
          class="text-sm text-white overflow-hidden transition-all duration-300 whitespace-nowrap"
          :class="{ 'w-0 ml-0 opacity-0': !isExpanded, 'w-auto ml-2 opacity-100': isExpanded }"
        >
          images générées avec Almana.cc Beta
        </span>
      </div>
    </button>
  </div>
</template>

<script setup>
import NumberFlow from '@number-flow/vue'

definePageMeta({
  layout: 'splash',
})

const isExpanded = ref(false)
const { data: imageCount, refresh, status } = await useLazyAsyncData('imageCount', () => $fetch('/api/ai/imageCount'))

onMounted(async () => {
  setInterval(async () => {
    if (status.value !== 'pending') {
      await refresh()
    }
  }, 1000)
})
</script>
